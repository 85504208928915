import { defineComponent } from 'vue';
import TeamView from '../TeamView';
import TeamEdit from '../TeamEdit';
import { StringFormatter } from '@/classes/StringFormatter';
import { getScoresForTeam } from '@/types/teams';
import { useToast } from 'vue-toastification';
import { ModalBox, Button } from '@/components/UI';
import { ScanTeamButton } from '@/components/Schedule';
import useMobile from "@/compositions/useMobile";
import Table from '@/components/UI/Table';
import { cloneDeep } from 'lodash';
import TeamQrCode from '../TeamQrCode';
export default defineComponent({
    name: 'TeamsTable',
    components: {
        TeamView,
        TeamEdit,
        Table,
        ModalBox,
        Button,
        TeamQrCode,
        ScanTeamButton
    },
    props: {
        teamtype: {
            type: String,
            required: true
        },
        actions: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    setup() {
        return {
            toast: useToast(),
            ...useMobile()
        };
    },
    data: () => ({
        editTeam: null,
        perpage: 10,
        selectedTeamIndex: null,
        showQrCode: null,
        editVisible: false
    }),
    computed: {
        now() {
            return this.$store.direct.state.clock.now;
        },
        teams() {
            return this.$store.direct.state.teams[this.teamtype];
        },
        selectedTeam() {
            var _a;
            if (this.selectedTeamIndex === null) {
                return null;
            }
            return (_a = this.teams[this.selectedTeamIndex]) !== null && _a !== void 0 ? _a : null;
        },
        qrTeam() {
            var _a;
            if (this.showQrCode === null)
                return null;
            return (_a = this.teams[this.showQrCode]) !== null && _a !== void 0 ? _a : null;
        },
        buttonscale() {
            return this.isMobile ? 0.6 : 1;
        },
        teamTable() {
            const table = [];
            Object.values(this.teams).forEach(team => {
                var _a, _b, _c;
                const member = {
                    ...team,
                    score: Object.values(getScoresForTeam(team)).reduce((sum, current) => sum + current, 0),
                    numgames: team.games !== undefined ? team.games.length : 0,
                    nummembers: team.members !== undefined ? team.members.length : 0,
                    id: team._id,
                    duration: StringFormatter.durationToString(team.duration),
                    badge: (_c = (_b = this.specialRfids[((_a = team.members[0]) === null || _a === void 0 ? void 0 : _a.rfid) + '']) === null || _b === void 0 ? void 0 : _b.text) !== null && _c !== void 0 ? _c : ''
                };
                if (this.teamtype === 'active' && team.start_time !== undefined && team.start_time !== null) {
                    member.timeLeft = team.start_time - this.now + team.duration;
                    member.duration = StringFormatter.durationToString(member.timeLeft);
                }
                table.push(member);
            });
            return table;
        },
        fields() {
            const fieldlist = [];
            fieldlist.push({ key: 'start_time', name: 'Start', sortable: true });
            fieldlist.push({ key: 'name', name: 'Name', sortable: false });
            if (this.teamtype !== 'waiting') {
                fieldlist.push({ key: 'score', name: 'Score', sortable: true });
            }
            if (this.teamtype !== 'waiting' && !this.isMobile) {
                fieldlist.push({ key: 'numgames', name: 'Games', sortable: false });
            }
            if (!this.isMobile) {
                fieldlist.push({ key: 'nummembers', name: '#Mem', sortable: false });
            }
            fieldlist.push({ key: 'badge', name: 'Badge', sortable: false });
            fieldlist.push({ key: 'duration', name: 'Time', sortable: true });
            if (this.actions.length > 0) {
                fieldlist.push({ key: 'actions', name: 'Actions', sortable: false });
            }
            return fieldlist;
        },
        specialRfids() {
            return this.$store.direct.state.rfid.specialRFID;
        }
    },
    mounted() {
        this.$store.direct.dispatch.teams.subscribe(this.teamtype);
        this.$store.direct.dispatch.rfid.subscribe();
    },
    unmounted() {
        this.$store.direct.dispatch.teams.unsubscribe(this.teamtype);
        this.$store.direct.dispatch.rfid.unsubscribe();
    },
    methods: {
        startEditTeam(id) {
            this.editVisible = true;
            const team = this.teams[id];
            if (team !== undefined) {
                this.editTeam = cloneDeep(team);
            }
        },
        async updateTeam(team, action) {
            const successmessage = action === 'moved' ? `Team moved to ${team.state}` : `Team ${team.name} has been saved`;
            const failmessage = action === 'moved' ? 'Could not move team, ' : 'Could not update team, ';
            try {
                await this.$store.direct.dispatch.teams.update(team);
                this.toast.success(successmessage);
            }
            catch (error) {
                this.toast.error(failmessage + error);
            }
            this.editVisible = false;
        },
        async deleteTeam(teamid) {
            try {
                await this.$store.direct.dispatch.teams.delete(teamid);
                this.toast.success(`Team has been deleted`);
            }
            catch (error) {
                if (error instanceof Error) {
                    this.toast.error(error.message);
                }
            }
        }
    }
});
